:root {
    background-color: var(--background) !important;
    
}

.title-img img {
    width: 10%;
    height: 10%;
    filter: invert(100%);
}

.hero {
    font-size: large;
    font-weight: bold;
    padding-top: 8%;
}

article {
    margin-top: 0;
    border-radius: 1.25em;
    background-color: var(--card-background-color);
    backdrop-filter: blur(10px) opacity(20%) !important;
    -webkit-backdrop-filter: blur(10px) opacity(80%) !important;
}

div[id='post-list'] {
    width: 60vw !important;
}

section[id='post-editor'] {
    width: 60vw !important;
}

article[id='post'] {
    width: 60vw !important;
}

div[id='nav'] {
    margin-left: 25vw;
}

div[id='main-grid'] article {
    width: 60vw !important;
}

div#post-list.grid {
    display: grid;
    grid-template-columns: 100px 65% !important;
}

div[id='post-preview'] div {
    width: calc(100%);
    height: 2em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis !important;
}

div[id='post-draft'] {
    position: relative;
    color: var(--primary);
    text-align: right;
    top: 2em !important;
}

img[id='hero'] {
    width: 50%;
    height: 350px;
    object-fit: cover;
    margin-left: 25%;
    margin-right: 25%;
}

img[id='hero-thumb'] {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: 2px solid var(--primary);
    overflow: hidden;
}

div[class='hero-placeholder'] {
    height: 100px;
    width: 100px;
    overflow: hidden;
    border: 2px solid var(--primary);
    border-radius: 50%;
    background-color: var(--secondary); 
}

div[id='post-hero'] {
    
}

@media (max-width: 991px) {
    div[id='nav'] {
        width: 100%;
        padding-left: 0 !important;
        margin-left: 0 !important;
        text-align: center;
    }
    div[id='post-list'] {
        width: 100% !important;
        padding: 0 !important;
    }
    section[id='post-editor'] {
        width: 100% !important;
        
    }
    article[id='post'] {
        width: 100% !important;
        
    }
    div[id='main-grid'] article {
        width: 100% !important;
    }
}

/* Pink Theme */
@media only screen and (prefers-color-scheme: light) {
    :root:is([data-theme="pink"]) {
    --primary: #d81b60;
    --primary-hover: #c2185b;
    --primary-focus: rgba(216, 27, 96, 0.125);
    --primary-inverse: #FFF;
    }
}

@media only screen and (prefers-color-scheme: dark) {
    :root:is([data-theme="pink"]) {
        color-scheme: dark;
        --primary: #d81b60;
        --primary-hover: #e91e63;
        --primary-focus: rgba(216, 27, 96, 0.25);
        --primary-inverse: #FFF;
    }
  }

  :root:is([data-theme='pink']) {
    --form-element-active-border-color: var(--primary);
    --form-element-focus-color: var(--primary-focus);
    --switch-color: var(--primary-inverse);
    --switch-checked-background-color: var(--primary);
  }

/* Purple Theme */

@media only screen and (prefers-color-scheme: light) {
    :root:is([data-theme="purple"]) {
        --primary: #8e24aa;
        --primary-hover: #9c27b0;
        --primary-focus: rgba(142, 36, 170, 0.25);
        --primary-inverse: #FFF;
    }
}

@media only screen and (prefers-color-scheme: dark) {
    :root:is([data-theme="purple"]) {
        color-scheme: dark;
        --primary: #8e24aa;
        --primary-hover: #9c27b0;
        --primary-focus: rgba(142, 36, 170, 0.25);
        --primary-inverse: #FFF;
    }
  }

  :root:is([data-theme='purple']) {
    --form-element-active-border-color: var(--primary);
    --form-element-focus-color: var(--primary-focus);
    --switch-color: var(--primary-inverse);
    --switch-checked-background-color: var(--primary);
  }

  /* Orange Theme */

@media only screen and (prefers-color-scheme: light) {
    :root:is([data-theme="orange"]) {
    --primary: #fb8c00;
    --primary-hover: #f57c00;
    --primary-focus: rgba(251, 140, 0, 0.125);
    --primary-inverse: #FFF;
    }
}

@media only screen and (prefers-color-scheme: dark) {
    :root:is([data-theme="orange"]) {
    color-scheme: dark;
    --primary: #fb8c00;
    --primary-hover: #ff9800;
    --primary-focus: rgba(251, 140, 0, 0.25);
    --primary-inverse: #FFF;
    }
  }

  :root:is([data-theme='orange']) {
    --form-element-active-border-color: var(--primary);
    --form-element-focus-color: var(--primary-focus);
    --switch-color: var(--primary-inverse);
    --switch-checked-background-color: var(--primary);
  }

  /* Blue Theme */

@media only screen and (prefers-color-scheme: light) {
    :root:is([data-theme="blue"]) {
        --primary: #039be5;
        --primary-hover: #0288d1;
        --primary-focus: rgba(3, 155, 229, 0.125);
        --primary-inverse: #FFF;
    }
}

@media only screen and (prefers-color-scheme: dark) {
    :root:is([data-theme="blue"]) {
    color-scheme: dark;
    --primary: #039be5;
    --primary-hover: #03a9f4;
    --primary-focus: rgba(3, 155, 229, 0.25);
    --primary-inverse: #FFF;
    }
  }

  :root:is([data-theme='blue']) {
    --form-element-active-border-color: var(--primary);
    --form-element-focus-color: var(--primary-focus);
    --switch-color: var(--primary-inverse);
    --switch-checked-background-color: var(--primary);
  }

    /* Green Theme */

@media only screen and (prefers-color-scheme: light) {
    :root:is([data-theme="green"]) {
        --primary: #7cb342;
        --primary-hover: #689f38;
        --primary-focus: rgba(124, 179, 66, 0.125);
        --primary-inverse: #FFF;
    }
}

@media only screen and (prefers-color-scheme: dark) {
    :root:is([data-theme="green"]) {
    color-scheme: dark;
    --primary: #7cb342;
    --primary-hover: #8bc34a;
    --primary-focus: rgba(124, 179, 66, 0.25);
    --primary-inverse: #FFF;
    }
  }

  :root:is([data-theme='green']) {
    --form-element-active-border-color: var(--primary);
    --form-element-focus-color: var(--primary-focus);
    --switch-color: var(--primary-inverse);
    --switch-checked-background-color: var(--primary);
  }

  /* Cloud Theme */

@media only screen and (prefers-color-scheme: light) {
    :root:is([data-theme="cloud"]) {
        --primary: #fff;
        --primary-hover: #480355;
        --primary-focus: rgba(70, 3, 84, 0.125);
        --primary-inverse: #ffffff;
        --background: #90FCF9;
        --card-background-color: #fff;
        --color: #63B4D1;
    }
}

@media only screen and (prefers-color-scheme: dark) {
    :root:is([data-theme="cloud"]) {
    color-scheme: dark;
    --primary: #fff;
    --primary-hover: #480355;
    --primary-focus: rgba(70, 3, 84, 0.125);
    --primary-inverse: #ffffff;
    --background: #7698d49c;
    --card-background-color: #fff;
    --color: #63B4D1;
    }
  }

  :root:is([data-theme='green']) {
    --form-element-active-border-color: var(--primary);
    --form-element-focus-color: var(--primary-focus);
    --switch-color: var(--primary-inverse);
    --switch-checked-background-color: var(--primary);
  }

      